<template>
  <v-card-text>

    <!-- payment method delete confirmation modal -->
    <v-dialog
      v-if="project && billingAccount"
      v-model="removePaymentMethodModal"
      max-width="500px"
    >
      <v-card>
        <v-card-title>Confirmation Required</v-card-title>
        <v-card-text>
          Are you sure want to remove payment card
          {{ billingAccount.paymentMethodCardBrand }} ending in
          {{ billingAccount.paymentMethodCardLast4 }}?
          
          <br>
          Subscriptions without
          valid payment method will be cancelled once the term ends.
        </v-card-text>
        <v-card-actions>
          <v-btn
            small outlined text
            v-on:click.native="removePaymentMethodModal = false"
            class="secondary--text"
            >Cancel</v-btn
          >
          <v-spacer> </v-spacer>
          <v-btn
            v-on:click.native="deleteCardConfirmed()"
            small color="primary"
          >
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-list dense v-if="billingAccount">
      <v-list-item class="mb-4">
        <v-list-item-content two-line>
          <span class="font-weight-bold">Billing information</span>
          <v-list-item-subtitle class="mt-2">
            <span class="caption">
              See our <router-link
                    :to="{
                      name: 'plans',                      
                    }"
                    > pricing
                  </router-link> for a more detailed analysis of what Meteron has on offer. 
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-btn class="shiny-button" small :to="{
            name: 'plans',
            params: {

            },
          }" router>
            Change subscription
          </v-btn>
        </v-list-item-icon>
        <v-list-item-icon></v-list-item-icon>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <span class="font-weight-bold mt-1">Project ID</span>
        </v-list-item-content>
        <v-list-item-content v-if="project" class="mt-1">
          <span class="text-right">
            {{ project.id }}
          </span>
        </v-list-item-content>
        <v-list-item-icon>
          <!-- just for alignment, not editable -->
        </v-list-item-icon>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <span class="font-weight-bold mt-1">Plan</span>
        </v-list-item-content>
        <v-list-item-content v-if="project" class="mt-1">
          <span class="text-right">
            {{
              $store.getters.getBillingPlan(
                billingAccount.stripePlanId
              ).name
            }}
            <v-chip small :color="
              getSubscriptionColour(
                billingAccount.stripeSubscriptionStatus
              )
            " outlined class="ml-2 mb-1">
              {{ billingAccount.stripeSubscriptionStatus }}
            </v-chip>
          </span>
        </v-list-item-content>
        <v-list-item-icon>
          <!-- just for alignment, not editable -->
        </v-list-item-icon>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <span class="font-weight-bold">Price</span>
        </v-list-item-content>
        <v-list-item-content class="align-end">
          <span class="text-right">
            ${{
              $store.getters.getBillingPlan(
                billingAccount.stripePlanId
              ).price / 100
            }} <span class="caption">per month</span>
          </span>
        </v-list-item-content>
        <v-list-item-icon></v-list-item-icon>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <span class="font-weight-bold">Next payment on</span>
        </v-list-item-content>
        <v-list-item-content class="align-end ">
          <span class="text-right">{{ billingAccount.currentPeriodEnd | ymd }}</span>

        </v-list-item-content>
        <v-list-item-icon></v-list-item-icon>
      </v-list-item>

      <v-list-item v-if="billingAccount.paymentMethodCardLast4">
        <v-list-item-content>
          <span class="font-weight-bold">Credit card</span>
        </v-list-item-content>
        <v-list-item-content class="align-end ">
          <span class="text-right">
            {{ billingAccount.paymentMethodCardBrand }}, ending with
            {{ billingAccount.paymentMethodCardLast4 }}            
          </span>

        </v-list-item-content>
        <v-list-item-icon>
          <v-icon small class="mr-2" @click="deleteCardIntent()">
            mdi-delete
          </v-icon>

        </v-list-item-icon>
      </v-list-item>

      <v-list-item v-if="billingAccount.paymentMethodCardExpMonth">
        <v-list-item-content>
          <span class="font-weight-bold">Credit card expiry</span>
        </v-list-item-content>
        <v-list-item-content class="align-end ">
          <span class="text-right">{{ billingAccount.paymentMethodCardExpMonth }} / {{ billingAccount.paymentMethodCardExpYear }}</span>

        </v-list-item-content>
        <v-list-item-icon></v-list-item-icon>
      </v-list-item>

      <v-list-item v-if="billingAccount.paymentMethodId === ''">
        <v-list-item-content></v-list-item-content>
        <v-list-item-action class="align-end">
          <v-btn
            class="primary"
            small
            outlined
            @click="addCard()"
            :disabled="billingAccount.paymentMethodId != ''"
          >
            <v-icon small class="mr-1">mdi-plus</v-icon>
            Add payment Card
          </v-btn>
        </v-list-item-action>
        <v-list-item-icon>
          
        </v-list-item-icon>
      </v-list-item>
    
    </v-list>
  </v-card-text>
</template>

<script>

export default {
  components: {
  },

  data() {
    return {
      removePaymentMethodModal: false
    }
  },

  props: {
    project: {
      type: Object,
      required: true
    },
  },

  computed: {
    billingPlans() {
      return this.$store.state.billing.billingPlans
    },
    billingAccount() {
      return this.$store.state.billing.billingAccount
    },
    loading() {
      return this.$store.state.billing.loading
    },
    error() {
      return this.$store.state.billing.error
    }
  },

  watch: {
    removePaymentMethodModal(val) {
      val || this.cancelPaymentMethodDeletion()
    },
  },

  mounted() {
    this.refresh()
  },

  methods: {
    async refresh() {
      await this.$store.dispatch('ListBillingPlans')
      this.$store.dispatch('GetBillingAccount', {
        projectId: this.project.id,
      })
    },

    getSubscriptionColour(status) {
      // Switch between active, canceled, incomplete, incomplete_expired, trialing, past_due, unpaid
      switch (status) {
        case 'active':
          return 'green accent-3'
        case 'canceled':
          return 'red darken-3'
        case 'incomplete':
          return 'amber lighten-1'
        case 'incomplete_expired':
          return 'amber lighten-1'
        case 'trialing':
          return 'green accent-3'
        case 'past_due':
          return 'amber lighten-1'
        case 'unpaid':
          return 'amber lighten-1'
        default:
          return 'amber lighten-1'
      }
    },

    addCard() {
      this.$store.dispatch('AddPaymentMethod', {
        projectId: this.project.id,
      })
    },

    deleteCardIntent() {
      this.removePaymentMethodModal = true
    },

    async deleteCardConfirmed() {
      await this.$store.dispatch('RemovePaymentMethod', {
        projectId: this.project.id,
      })
      this.refresh()
      this.$store.dispatch('Notify', `Card removed from the account`)
      this.cancelPaymentMethodDeletion()
    },
    cancelPaymentMethodDeletion() {
      this.removePaymentMethodModal = false
    },
  }
}

</script>